@if (showFooter){
  <div class="fonbet">
    <a class="fonbet__logo" href="#">
      <img src="./assets/images/fonbet-2.svg" alt="logo">
    </a>
    <div class="fonbet__flex">
      <button class="fonbet__gift" (click)="openGiftModal()"><span>Правила<br>игры</span>
        <img src="./assets/images/gift.svg" alt="logo"></button>
      <button class="fonbet__question"><img src="./assets/images/question.svg" alt="logo" (click)="toOnboarding()"></button>
    </div>
  </div>
}
