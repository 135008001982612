import {
	AfterViewInit,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	signal,
	ViewChild,
} from '@angular/core';
import { CityItem } from '@interfaces/city-item.interface';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { Router, RouterLink } from '@angular/router';
import { StateService } from '@services/state.service';
import { FooterComponent } from '@components/footer/footer.component';
import { map, Observable, of } from 'rxjs';
import { LevelRecord } from '@interfaces/level-record.interface';
import { ApiService } from '@services/api.service';

@Component({
	selector: 'app-main-page',
	standalone: true,
	imports: [
		NgOptimizedImage,
		SwiperDirective,
		RouterLink,
		FooterComponent,
		AsyncPipe,
	],
	templateUrl: './main-page.component.html',
	styleUrl: './main-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainPageComponent implements AfterViewInit {
	@ViewChild(SwiperDirective) swiper: SwiperDirective;
	private state = inject(StateService);
	private api = inject(ApiService);
	private router = inject(Router);
	public user = this.state.user;
	public userScore = this.state.userScore;
	public cityIndex = signal(0);
	public levelRecord$: Observable<LevelRecord>;
	public cities: CityItem[] = [
		{
			id: 1,
			name: 'Самара',
			directionName: 'Самару',
			image: './assets/images/city-samara.png',
			description: `Первой остановкой для ЛамПАЗа станет Самара, где «Акрон» встретится с «Крылья Советов»! Едем только ради победы!`,
		},
		{
			id: 2,
			name: 'Казань',
			directionName: 'Казань',
			image: './assets/images/city-kazan.png',
			description: `Продолжаем наше Волжское турне! ЛамПАЗ мчится в один из самых древних и красивых городов России. Следующая остановка — Казань, где нас ждёт матч с «Рубином». Жми на газ!`,
		},
		{
			id: 3,
			name: 'Ростов-на-Дону',
			directionName: 'Ростов-на-Дону',
			image: './assets/images/city-rostov.png',
			description: `Мы на полпути! Держите руль крепче. Теперь ЛамПАЗ отправляется в путешествие до Ростова-на-Дону, чтобы одержать победу над «Желто-синими». Команда рассчитывает на вас!`,
		},
		{
			id: 4,
			name: 'Москва',
			directionName: 'Москву',
			image: './assets/images/city-moscow.png',
			description: `Мчим ЛамПАЗ покорять столицу! Следующая остановка для «Акрон» — Москва, где нас уже ждёт матч с ПФК ЦСКА. Выкладываемся на максимум!`,
		},
		{
			id: 5,
			name: 'С.Петербург',
			directionName: 'С.Петербург',
			image: './assets/images/city-spb.png',
			description: `Финальной остановкой в маршруте ЛамПАЗа станет культурная столица России, где мы сразимся с «Зенит». Покажем им настоящий футбол родом из Тольятти!`,
		},
	];

	constructor() {
		this.cities = [...this.cities].slice(0, this.state.levelId);
		const userScore = this.state.getUserScoreForLevel(this.state.levelId);
		if (userScore > 0) {
			this.levelRecord$ = this.api.getUserPlaceForLevel(
				this.state.levelId,
				userScore
			);
		} else {
			this.levelRecord$ = this.api
				.getUserPlaceForLevel(this.state.levelId, 1)
				.pipe(map(data => ({ record: data.record, place: null })));
		}
	}

	ngAfterViewInit() {
		const level = this.state.levelId;
		this.swiper.swiper.slideTo(level - 1);
	}

	changeCityName(index: number) {
		this.cityIndex.set(index);
		const level = index + 1;
		const userScoreForLevel = this.state.getUserScoreForLevel(level);
		if (userScoreForLevel > 0) {
			this.levelRecord$ = this.api.getUserPlaceForLevel(
				level,
				userScoreForLevel
			);
		} else {
			this.levelRecord$ = this.api
				.getUserPlaceForLevel(level, 1)
				.pipe(map(data => ({ record: data.record, place: null })));
		}
	}

	saveScore() {
		this.router.navigate(['/save']);
	}
}
